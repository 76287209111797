/* eslint-disable no-useless-escape */
import React, { FC } from 'react';

import styles from '../ModalEmbedCode.module.scss';
import buttonStyles from '../Button.module.scss';

import GTMWebsiteOption from '@components/NoVerifiedWebsiteModal/Options/GTMWebsiteOption';
import { makeSelectWebsites } from '@connectors/websites/reducer';
import { useSelector } from 'react-redux';
import PSButton from '@elements/PSButton';
import { GetFreeHelpWithVerificationButton } from './Default';
import { useTranslation } from 'react-i18next';

interface GtmWebsiteProps {
  onVerified: (status: boolean) => void;
  onClose: () => void;
}

const GtmWebsite: FC<GtmWebsiteProps> = ({ onVerified, onClose }) => {
  const { t } = useTranslation();
  const websites = useSelector(makeSelectWebsites);

  const handleVerifyComplete = () => {
    if (!websites?.[0]?.url) return;
    onVerified && onVerified(true);
  };

  return (
    <>
      <div className={`${styles.modalContent} !mt-2`}>
        <GTMWebsiteOption
          routerReload={true}
          onVerifyComplete={handleVerifyComplete}
          withWebsiteVerification={false}
          showHelpButton={false}
        />
        <div className="mt-6">
          <div className={buttonStyles.button}>
            <PSButton
              variant={'second'}
              textClassName="text-[#777777] text-[12px]"
              onClick={() => {
                onClose();
              }}
            >
              {t('common:close')}
            </PSButton>
            <GetFreeHelpWithVerificationButton />
          </div>
        </div>
      </div>
    </>
  );
};

export default GtmWebsite;
