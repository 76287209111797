/* eslint-disable no-useless-escape */
import React, { FC } from 'react';

import PSButton from '@elements/PSButton';

import styles from '../ModalEmbedCode.module.scss';

import { GetFreeHelpWithVerificationButton } from './Default';

import PlatformBadge from '../PlatformBadge';
import WordpressIcon from '@assets/icons/wordpress-icon.svg';

import { useTranslation } from 'react-i18next';

interface WordpressWebsiteProps {
  onClose: () => void;
}

const WordpressWebsite: FC<WordpressWebsiteProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const handleOnClickGoToWordpressAppButton = () => {
    window.open('https://wordpress.org/plugins/popupsmart/', '_blank');
  };

  return (
    <>
      <div className={styles.modalContent}>
        <PlatformBadge
          cmsIcon={WordpressIcon}
          title={t('sections:embed-code-modal:tabWordpressTitle')}
          button={{
            onClick: handleOnClickGoToWordpressAppButton,
            text: t('sections:embed-code-modal:tabWordpressButton'),
          }}
          buttonBackgroundColor="wordpress"
          overlayBackgroundColor="rgba(0, 0, 0, 0.2)"
        />
        <div className={styles.button}>
          <PSButton
            variant={'second'}
            textClassName="text-[#777777] text-[12px]"
            onClick={() => {
              onClose();
            }}
          >
            {t('common:close')}
          </PSButton>
          <GetFreeHelpWithVerificationButton />
        </div>
      </div>
    </>
  );
};

export default WordpressWebsite;
