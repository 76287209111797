import React, { FC, useCallback } from 'react';
import dynamic from 'next/dynamic';

import PsModal from '@components/PsModal';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '@connectors/modal/actions';
import { makeSelectWebsites } from '@connectors/websites/selectors';
import { useAuth } from '@utils/useAuth';
import Tabs, { Tab } from '@elements/Tabs';
import styles from './ModalEmbedCode.module.scss';
import EmbedCodeIcon from '@assets/icons/embed-code-modal.svg';
import PopupsmartIcon from '@assets/icons/popupsmart-rounded.svg';
import WordpressIcon from '@assets/icons/wordpress-icon.svg';
import ShopifyIcon from '@assets/icons/shopify-line.svg';
import cn from 'classnames';
import { TabIconLabel } from '@elements/Tabs/TabIconLabel';
import WordpressWebsite from './Modal/Wordpress';
import { DynamicTabIconLabel } from '@elements/Tabs/DynamicTabIconLabel';
import GTMBlackIcon from '@assets/icons/google-tag-manager-black-icon.svg';
import GTMGrayIcon from '@assets/icons/google-tag-manager-grey-icon.svg';
import GtmWebsite from './Modal/GtmWebsite';
import { useTranslation } from 'react-i18next';

const Default = dynamic(() => import('./Modal/Default'));
const ShopifyWebsite = dynamic(() => import('./Modal/Shopify'));

type ModalEmbedCodeProps = {
  onVerified?: (status: boolean) => void;
};

const ModalEmbedCode: FC<ModalEmbedCodeProps> = ({ onVerified }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    auth: { accountId },
  } = useAuth();

  const websites = useSelector(makeSelectWebsites);

  const otherWebsiteCmsSize = websites?.filter(
    (website) => website?.cms !== 'WordPress' && website?.cms !== 'Shopify',
  ).length;

  const shopifyWebsiteSize = websites?.filter(
    (website) => website?.cms === 'Shopify',
  ).length;

  const WordpressWebsiteSize = websites?.filter(
    (website) => website?.cms === 'WordPress',
  ).length;

  const getDefaultActiveTab = () => {
    const isOtherWebsiteCmsSizeGreater =
      otherWebsiteCmsSize > shopifyWebsiteSize &&
      otherWebsiteCmsSize > WordpressWebsiteSize;

    switch (true) {
      case isOtherWebsiteCmsSizeGreater ||
        (shopifyWebsiteSize === 0 && WordpressWebsiteSize === 0):
        return 'custom';

      case shopifyWebsiteSize === WordpressWebsiteSize ||
        shopifyWebsiteSize > WordpressWebsiteSize:
        return 'shopify';

      case shopifyWebsiteSize < WordpressWebsiteSize:
        return 'wordpress';

      default:
        return 'custom';
    }
  };

  const closeModal = useCallback(() => {
    dispatch(hideModal('embedCode'));
  }, [dispatch]);

  if (!accountId) return null;

  const handleVerifyComplete = () => {
    if (!websites?.[0]?.url) return;
    onVerified && onVerified(true);
  };

  return (
    <PsModal
      id="embedCode"
      className="!top-1/2 !-translate-y-1/2 md:!top-auto md:!translate-y-0"
    >
      <div>
        <div className={styles.embedCode}>
          <div className={styles.embedCodeHead}>
            <div className="flex">
              <div className={styles.icon}>
                <PopupsmartIcon width="32" height="32" viewBox="0 0 48 48" />
              </div>
              <div className={cn(styles.icon, 'ml-[-10px] bg-white')}>
                <EmbedCodeIcon width="32" height="32" viewBox="0 0 46 46" />
              </div>
            </div>
            <h4 className="font-bold text-lg leading-6 mt-[15px] mb-[25px]">
              {t('sections:embed-code-modal:title')}
            </h4>
          </div>

          <Tabs
            defaultActiveKey={getDefaultActiveTab()}
            labelSize="sm"
            // onClickEvent={handleOnClickLabel}
            headerClassName="pl-10 !mb-[10px]"
          >
            <Tab id="custom" label={t('sections:embed-code-modal:tabOne')}>
              <Default accountId={accountId} onClose={closeModal} />
            </Tab>
            <Tab
              id="shopify"
              label={
                <TabIconLabel
                  text="Shopify"
                  icon={<ShopifyIcon className="#999999 " />}
                  tabId="shopify"
                />
              }
            >
              <ShopifyWebsite
                withShopifyApp
                accountId={accountId}
                onClose={closeModal}
              />
            </Tab>
            <Tab
              id="gtm"
              label={
                <DynamicTabIconLabel
                  text="GTM"
                  icon={<GTMGrayIcon />}
                  activeIcon={<GTMBlackIcon />}
                  tabId="gtm"
                />
              }
            >
              <GtmWebsite
                onClose={closeModal}
                onVerified={handleVerifyComplete}
              />
            </Tab>
            <Tab
              id="wordpress"
              className="justify-center"
              label={
                <TabIconLabel
                  text="Wordpress"
                  icon={
                    <WordpressIcon height="16" width="16" viewBox="0 0 18 18" />
                  }
                  tabId="wordpress"
                />
              }
            >
              <WordpressWebsite onClose={closeModal} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </PsModal>
  );
};

export default ModalEmbedCode;
